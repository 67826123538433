@import url("https://fonts.googleapis.com/css2?family=Noto+Sans:ital,wght@0,200;0,300;0,400;0,700;1,700&display=swap");
@import "normalize.css/normalize.css";
@import "../src/variables/variables.scss";

body {
	width: 100vw;
	height: 100vh;
	font-family: -apple-system, "Noto Sans", BlinkMacSystemFont, "Segoe UI",
		"Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
		"Helvetica Neue", sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	display: flex;
	align-items: center;
	justify-content: center;
	margin: 0 auto;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
		monospace;
}

:root a {
	color: $text;
	&:hover {
		color: $accent-one-dark;
		transition: 500ms;
	}
}

.bg-img {
	position: fixed;
	left: 0;
	max-width: 100vw;
	top: 0;
	height: auto;
	opacity: 0.4;
	z-index: -5;
}
