@import "../../variables/variables.scss";

.pose-of-the-day__container {
	margin-top: 350px;
	margin-left: auto;
	margin-right: auto;
	position: relative;
	display: flex;
	flex-direction: column;
	align-items: center;
	height: fit-content;
	width: 60vw;
	bottom: 100px;
	overflow: auto;
	box-sizing: border-box;
	-ms-overflow-style: none; /* IE and Edge */
	scrollbar-width: none;
}

.pose-of-the-day__container::-webkit-scrollbar {
	display: none;
}

.pose__container {
	display: flex;
	align-items: center;
	padding: 20px 135px;
	margin-bottom: 50px;
	width: 100%;
	height: fit-content;
	background-color: $background-dark;
	filter: drop-shadow($drop-shadow);
	border-radius: 10px;
	overflow: auto;
	-ms-overflow-style: none; /* IE and Edge */
	scrollbar-width: none;
}

.pose__container::-webkit-scrollbar {
	display: none;
}

.pose-of-the-day__alert-message {
	color: #d90966;
	font-size: x-large;
	font-weight: bold;
}

.pose-of-the-day {
	font-family: $cormorant-sc;
	font-size: small;
	flex: 1;
	color: $text;
	padding-right: 20px;
}

.pose__image {
	flex: 1;

	img {
		max-width: auto;
		max-height: 40vh;
	}
}

.pose-description__container {
	display: flex;
	font-family: $noto-sans;
	color: $text;
	text-align: justify;
	border-color: 1px solid #ebc789;
	border-radius: 10px;
	padding: 20px;
	width: 100%;
	background-color: $background-dark;
	filter: drop-shadow($drop-shadow);
	margin-bottom: 50px;
}
