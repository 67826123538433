@import "../../variables/variables.scss";

.pose-preview__container {
	position: relative;
	display: flex;
	flex-direction: column;
	max-height: 150px;
	// width: auto;
}

.pose-preview__english-name {
	font-size: 10px;
}
