@import "../../variables/variables.scss";

.footer {
	position: fixed;
	bottom: 0;
	// height: 50px;
	padding-top: 20px;
	padding-bottom: 40px;
	margin-top: 50px;
	width: 100vw;
}

.footer__copyright {
	font-family: $noto-sans;
	position: absolute;
	font-size: small;
	font-weight: 300;
	opacity: 0.6;
	color: $text;
	// padding-top: 20px;

	margin: auto;
	width: 25%;
	left: 50%;
	transform: translate(-50%, -50%);
}
