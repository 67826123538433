@import "../../variables/variables.scss";

body {
	position: relative;
	display: flex;
	justify-content: center;
	align-content: center;
	overflow: auto;
	width: 100vw;
	height: 100vh;
	align-items: center;
}

h1 {
	color: $text;
	font-family: $noto-sans;
	font-size: 80px;
	font-weight: 600;
}

.box {
	border: 1px solid $accent-one-dark;
	background-color: $accent-one-light;
	filter: drop-shadow($drop-shadow);
	font-family: $noto-sans;
}

.btn-primary {
	background-color: $accent-three-contrast;
	border-color: $background-dark;
	color: $text;
	&:hover {
		background-color: $accent-one-light;
		border-color: $text;
		color: $text;
		transition: 500ms;
	}
}

.g-btn {
	font-family: $noto-sans;
	width: 100% !important;
	height: 40px !important;
	line-height: 40px !important;
	font-size: 15px !important;
	background-color: $background-light;
	border-color: $background-dark;
	color: $text;
	box-shadow: none;
}
.g-btn > div,
.g-btn > div > svg {
	width: 40px !important;
	height: 38px !important;
	box-shadow: none;
}

.top-nav-bar {
	width: 100vw;
}
