@import "../../variables/variables.scss";

.poses-library {
	top: 300px;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;

	margin-right: 300px;
	margin-left: 300px;

	height: calc(100vh - 300px);
	overflow: auto;
	box-sizing: border-box;
	-ms-overflow-style: none; /* IE and Edge */
	scrollbar-width: none;
}

.poses-library::-webkit-scrollbar {
	display: none;
}

.alert-message {
	margin-left: 500px;
	color: #d90966;
	font-size: x-large;
	font-weight: bold;
}
.poses__container {
	display: grid;
	grid-template-columns: repeat(auto-fill, minmax(min(10rem, 100%), 1fr));
	gap: 20px;

	padding: 10px;

	overflow: auto;
	height: auto;
	min-width: 60vw;
	// margin-bottom: 30px;

	-ms-overflow-style: none; /* IE and Edge */
	scrollbar-width: none;
}

.poses__container::-webkit-scrollbar {
	display: none;
}

.pose-preview__grid-item {
	display: flex;
	flex-direction: column;
	align-items: center;
	border: 0;
	padding: 10px;
	padding-bottom: 30px;
	max-width: 150px;
	height: fit-content;
	background-color: $background-dark;
	filter: drop-shadow($drop-shadow);
	border-radius: 10px;
}

.pose-preview__grid-item img {
	padding: 5px;
	display: flex;
	max-width: 120px;
	height: auto;
}
