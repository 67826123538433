@import "../../variables/variables.scss";

.pose__modal {
	max-width: 50vw;
	text-justify: center;
	height: fit-content;
	margin: auto;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-around;
}

.pose__card {
	background-color: $background-light;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-around;
	padding: 20px;
}

.pose__image {
	// margin-top: 20px;
}

.pose__names {
	margin-bottom: 20px;
	font-family: $noto-sans;
	color: $text;
}

.pose__modal-button {
	text-align: justify;
	justify-self: center;
}
