@import "../../variables/variables.scss";

.dropdown {
	margin-right: 50px;
	margin-top: 30px;
	position: relative;
	display: flex;
}

.dropdown__hamburger-icon {
	width: 20px;
	height: auto;
	box-shadow: none;
	border-style: none;
	color: $text;
	&:hover {
		background-color: $background-light;
		transition: all 500ms ease-in-out;
	}
}

.dropdown-content {
	margin-top: 10px;
	position: absolute;
	width: 100%;
	transition: all 500ms ease-in-out;
	filter: drop-shadow($drop-shadow);
}

ul {
	position: absolute;
	background-color: $background-light;
	right: 10px;
	top: 40px;
	width: auto;
	border-radius: 10%;
	font-size: medium;
	padding-top: 20px;
	padding-right: 20px;
	padding-bottom: 20px;
	transition: all 500ms ease-in-out;
}

.dropdown-content__item {
	list-style-type: none;
}

.dropdown-content__item a {
	color: $text;
	width: max-content;
	&:hover {
		color: $accent-one-dark;
		transition: all 500ms ease-in-out;
	}
}
