@import "../../variables/variables.scss";

.my-favourites__container {
	top: 300px;

	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	margin-right: 300px;
	margin-left: 300px;

	max-height: calc(100vh - 300px);
	overflow: auto;
	box-sizing: border-box;
	-ms-overflow-style: none; /* IE and Edge */
	scrollbar-width: none;
}

.my-favourites__container::-webkit-scrollbar {
	display: none;
}

.title-text {
	font-family: $cormorant-sc;
	color: $text;
	font-size: 30px;
	margin-bottom: 50px;
}
.my-poses__container {
	display: grid;
	grid-template-columns: repeat(auto-fill, minmax(min(10rem, 100%), 1fr));
	gap: 20px;

	padding: 10px;

	overflow: auto;
	height: auto;
	min-width: 60vw;

	-ms-overflow-style: none; /* IE and Edge */
	scrollbar-width: none;
}

.my-poses__container::-webkit-scrollbar {
	display: none;
}

.my-poses__grid-item {
	display: flex;
	flex-direction: column;
	align-items: center;
	border: 0;
	padding: 10px;
	padding-bottom: 30px;
	max-width: 150px;
	height: fit-content;
	background-color: $background-dark;
	filter: drop-shadow($drop-shadow);
	border-radius: 10px;
}

.my-poses__grid-item img {
	padding: 5px;
	display: flex;
	height: auto;
	max-width: 120px;
}
